import React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const NotFoundPage = (props) => (

  <Layout>
    <Seo title='404: Not found' />

    <BackgroundImage className='BackgroundImage' fluid={props.data.BackgroundImage.childImageSharp.fluid}>
      <div className='container text-center Submit-title'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 '>
          <h1>404: Not Found</h1>
          <p>ops... sidan du söker finns inte..</p>
          <Link to='/' className='btn btn-danger'> Tillbaka till start </Link>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    BackgroundImage: file(relativePath: { eq: "ovrigt/404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`
